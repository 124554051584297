<template>
  <va-card v-if="showGrid" title="User">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="showGrid = false">Add</va-button>
      <va-button color="orange" v-if="isEnable" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>

    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="status" slot-scope="props">
        <i v-if="props.rowData.status == 1" class='fa fa-check-circle status-true'/>
        <i v-else class='fa fa-times-circle status-false'/>
      </template>
      <template slot="edit" slot-scope="props">
        <va-button flat small 
        color="gray" icon="fa fa-pencil" 
        @click="editUser(props.rowData)" 
        class="ma-0">
        </va-button>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create user':'update user'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addUser():updateUser()">
      <va-select
        label="Organization Name *"
        v-model="user.org_name"
        type="text"
        :options="orgOtns"
        placeholder="Select Organization Name"
        textBy="org_name"
        noClear
        required="true"/>
      <va-select
        v-if="isCreate"
        label="Role *"
        v-model="user.role"
        :options="roleOtns"
        placeholder="Select Role"
        noClear
        required="true"/>
      <va-input
        v-if="!isCreate"
        label="Role *"
        v-model="user.role"
        placeholder="Enter Role"
        :disabled="true"/>
      <va-input
        label="User Name *"
        v-model="user.user_name"
        type="text"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
        placeholder="Enter User Name"
        required="true"/>
      <va-input
        label="First Name *"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
        v-model="user.first_name"
        placeholder="Enter First Name"
        required="true"/>
      <va-input
        label="Last Name"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
        v-model="user.last_name"
        placeholder="Enter Last Name"
      />
      <va-input
        label="Password ( Maximum length is 20 characters )"
        v-model="user.password"
        type="password"
        minlength=8
        maxlength=20
        pattern="^[A-Za-z0-9][A-Za-z0-9_@!./#&+-^]*$"
        placeholder="Enter Password"
        :required="passwordRequiredStatus"/>
      <va-input
        label="Mobile Number *"
        v-model="user.mobile_number"
        pattern="^[6-9]{1}[0-9]{9}$"
        maxlength="10"
        placeholder="Enter Mobile Number"
        required="true"/>
      <va-checkbox
        label="Status"
        v-model="user.status"
      />
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)

export default {
  created(){
    this.getUsers()
    this.getOrgs()
  },
  data () {
    return {
      term:null,
      isEnable:false,
      perPage:'10',
      perPageOptions:['4', '6', '10', '20'],
      showGrid:true,
      isCreate:true,
      roleOtns:["RESELLER"],
      orgOtns:[],
      orgNameOtns:["TSC","TRANSCOADER"],
      user_id:'',
      users: [],
      user:{
        org_name:"",
        role:"",
        first_name:"",
        last_name:"",
        password:"",
        user_name:"",
        mobile_number:"",
        status:true,
      },
    }
  },
  computed: {
    passwordRequiredStatus(){
      return this.user.password ? true:false
    },
    fields () {
      return [
        {
          name: 'sno',
          width: "8%"
        },
        {
          name: 'org_name',
          title: 'Name',
          width: "25%"
        },
        {
          name: 'role',
          title: 'Role',
          width: "13%"
        },
        {
          name: 'user_name',
          title: 'User Name',
          width: "14%"
        },
        {
          name: 'mobile_number',
          title: 'Mobile Number',
          width: "16%"
        },
        {
          name: '__slot:status',
          title: 'Status',
          width: "20%"
        },        
        {
          name: '__slot:edit',
          dataClass: 'text-right',
          width: "20%"
        }
      ]
    },
    filteredData () {
      let filteredUser = search(this.term, this.users)
      return filteredUser
    }
  },
  methods:{
    getOrgs(){
      this.$http.get(config.menu.host+'org')
      .then(res => {
        res.data.map((org,i) => org.id = i+1)
        this.orgOtns = res.data
      })
    },
    exportData(){
      var rowKeys = {
        org_name: 'Organization Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        mobile_number: 'Mobile Number',
        role:'Role'
      }
      return exportToFile(this.users, rowKeys, 'User', 'CSV')
    },
    editUser(user){
      Object.entries(this.user).map(key => {
        if(user[key[0]] && key[0] != 'password') this.user[key[0]] = user[key[0]]
        if(key[0] == 'status'){
          user[key[0]]? 
          this.user[key[0]] = true : 
          this.user[key[0]] = false
        }
      })
      this.user_id = user.user_id
      this.showGrid = false
      this.isCreate = false
    },
    resetValue(){
      this.showGrid = true
      this.isCreate = true
      Object.entries(this.user).forEach(key => {
        if (key[0] == 'status') this.user[key[0]] = true
        else this.user[key[0]] = ''
      })
    },
    getUsers(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'user')
      .then(res => {
        loader.hide()
        this.users = res.data
        this.isEnable = (this.users.length > 0) ? true : false
        this.users.map((user,i) => user.sno = i+1)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body, type: 'error'})
      })
    },
    validation(bool){
      for (const key in this.user) {
        if (!bool && key == 'password') continue
        if (!bool && key == 'last_name') continue
        if (key != 'status' && this.user[key] == ""){
          Vue.notify({
            text: 'Please fill the form correctly',
            type: 'error',
          })      
          return false
        }
      }
      return true
    },
    addUser(){
      if (!this.validation()) return
      let org = this.user.org_name
      this.user.org_name = org.org_name
      this.user.org_id = org.org_id
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host+'user',this.user)
      .then(function (res){
        loader.hide()
        Vue.notify({ text: res.body, type: 'success'})
        this.resetValue()
        this.getUsers()
      },function (err){
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      })
    },
    updateUser(){
      if (!this.validation(false)) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+`user/${this.user_id}`,this.user)
      .then(function (res) {
        loader.hide()
        Vue.notify({text: res.body,type: 'success'})
        this.resetValue()
        this.getUsers()
      }, function (err){
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  }
}
</script>

<style scoped>
.badge {
  padding: 8px 0.5rem;
}

.status-true {
  color: green;
  padding-left: 1rem;
}

.status-false {
  color: red;
  padding-left: 1rem;
}
</style>